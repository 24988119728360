<template>
  <base-layout-two page-title="Purchasables" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Purchasables</h1>
      <ion-button class="regular-button" expand="block" router-link="/purchasables/create"
        >New Purchasable</ion-button
      >
    </section>

    <ion-list class="padding-x-16">
      <purchasable-list-item
        v-for="purchasable in purchasables"
        :key="purchasable.id"
        :router-link="`/purchasables/${purchasable.id}`"
        :purchasable="purchasable"
      >
      </purchasable-list-item>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import PurchasableListItem from "@/components/purchasables/PurchasableListItem.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonList,
    IonButton,
    PurchasableListItem,
  },

  computed: {
    ...mapGetters(["globalProperty"]),
  },

  data() {
    return {
      purchasables: [],
    };
  },

  ionViewWillEnter() {
    this.fetchPurchasables();
  },

  methods: {
    async fetchPurchasables() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/purchasables?property_id=${this.globalProperty.id}`
        )
        .then((response) => {
          this.purchasables = response.data.purchasables;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>